import { render, staticRenderFns } from "./HealthUnit.vue?vue&type=template&id=35a89655&scoped=true&"
import script from "./HealthUnit.vue?vue&type=script&lang=js&"
export * from "./HealthUnit.vue?vue&type=script&lang=js&"
import style0 from "./HealthUnit.vue?vue&type=style&index=0&id=35a89655&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a89655",
  null
  
)

export default component.exports