<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/mesoregion"
              >
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link
              to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/mesoregion"
            >
              <h3 class="title">
                Mapas de ocupação Hospitalar mesorregião
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: SESAPI
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/microregion"
              >
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link
              to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/microregion"
            >
              <h3 class="title">
                Mapas de ocupação Hospitalar por microrregião
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: SESAPI
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/city"
              >
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link
              to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/city"
            >
              <h3 class="title">
                Mapas de ocupação Hospitalar por cidades
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: SESAPI
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/mesoregion_sesapi"
              >
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link
              to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/mesoregion_sesapi"
            >
              <h3 class="title">
                Mapas de ocupação Hospitalar por Macro Regiões de Saúde
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: SESAPI
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/microregion_sesapi"
              >
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link
              to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/microregion_sesapi"
            >
              <h3 class="title">
                Mapas de ocupação Hospitalar das Regionais de Saúde
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: SESAPI
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/microregion_sesapi_covid"
              >
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link
              to="/mosaico/health_unit/maps/sesapi/occupation_of_health_units/microregion_sesapi_covid"
            >
              <h3 class="title">
                Mapas de ocupação Hospitalar das regionais de Saúde COVID
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: SESAPI
            </div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import { StatsCard } from "@/components";

export default {
  components: {
    StatsCard
  },
  data() {
    return {};
  },
  mounted() {
    this.welcomeMessage();
  },
  props: {
    brasilIo: {
      type: String,
      default: require("@/assets/img/logo-brasil-io.png")
    }
  },
  methods: {
    welcomeMessage() {
      this.$emit("welcomeMessage", {
        title: "Mapas de Ocupação Hospitalar"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card-header {
  min-height: 110px;
}

h3 {
  min-height: 76px;
  margin-bottom: 1px;
}
</style>
